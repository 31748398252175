import { Box, useMediaQuery, useTheme } from '@mui/material'
import { Asset } from 'contentful'
import Image from 'next/image'
import Carousel, { Alignment } from 'nuka-carousel'
import { FunctionComponent, useEffect, useRef } from 'react'
import useSWR from 'swr'
import { marketingClient } from 'lib/contentful'
import { getSpacingNumber } from 'lib/utils'
import { LeftControl, RightControl } from './CarouselControls'

type Props = {
  images?: Asset[]
}

const StudioImageCarousel: FunctionComponent<Props> = ({ images }) => {
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'))
  const carouselWrapperRef = useRef(null)

  useEffect(() => {
    if (carouselWrapperRef?.current) {
      carouselWrapperRef.current.tabIndex = -1
    }
  }, [])

  const imagesFetcher = async () => {
    const entry = await marketingClient.getEntry<any>(
      process.env.NEXT_PUBLIC_CTF_ENTRIES_HOMEPAGE,
      {
        include: 2,
        content_type: 'homepage',
        select: 'fields.smallOfficeImages',
      },
    )

    return entry.fields.smallOfficeImages
  }

  const { data: fetchedImages } = useSWR(
    !images || images?.length === 0 ? '/contentful/homepage' : null,
    imagesFetcher,
  )

  if (fetchedImages) {
    images = fetchedImages
  }

  const cellSpacing = getSpacingNumber(theme, 2)

  return (
    <>
      <Box
        sx={{
          '& .slider-frame': {
            left: { xs: `-${cellSpacing / 2}px !important`, sm: 0 },
            width: {
              xs: `calc(100% + ${cellSpacing / 2}px) !important`,
              sm: '100%',
            },
            overflowX: 'hidden',
          },
        }}
      >
        <Carousel
          innerRef={carouselWrapperRef}
          renderCenterLeftControls={({ previousSlide }) =>
            !mdUp ? null : (
              <LeftControl
                aria-label="previous"
                onClick={previousSlide}
                iconSize="lg"
              />
            )
          }
          renderCenterRightControls={({ nextSlide }) =>
            !mdUp ? null : (
              <RightControl
                aria-label="next"
                onClick={nextSlide}
                iconSize="lg"
              />
            )
          }
          renderBottomCenterControls={null}
          cellAlign={xsDown ? Alignment.Left : Alignment.Center}
          slidesToShow={mdUp ? 1.25 : 1.15}
          cellSpacing={cellSpacing}
          wrapAround
          frameAriaLabel="Studio image carousel"
          aria-roledescription="carousel"
        >
          {images?.map((img) => (
            <Box
              sx={{
                position: 'relative',
                height: { xs: 375, sm: 490, md: 682, xl: 969 },
                width: '100%',
              }}
              key={img.fields.title}
            >
              <Image
                src={'https:' + img.fields.file.url}
                alt={img.fields.title}
                aria-roledescription="slide"
                aria-live="polite"
                fill
                style={{ objectFit: 'cover' }}
                sizes="100vw"
              />
            </Box>
          ))}
        </Carousel>
      </Box>
    </>
  )
}

export default StudioImageCarousel
