import { ButtonBase, SxProps, Theme } from '@mui/material'
import { FunctionComponent } from 'react'
import { toSxArray } from 'lib/utils'
import zIndexes from 'lib/zIndexes'
import { colors } from '../lib/root'
import LeftArrow from './icons/LeftArrow'

const controlStyles = {
  backgroundColor: colors.defaults.white,
  height: { xs: 48, md: 64 },
  width: { xs: 48, md: 64 },
  zIndex: zIndexes.standard,
  color: colors.core.grey[700],
  '&:hover': {
    filter: 'brightness(0.9)',
  },
}

interface ControlProps {
  onClick: () => void
  iconSize: 'xs' | 'sm' | 'md' | 'lg'
  sx?: SxProps<Theme>
  tabIndex?: number
}

export const LeftControl: FunctionComponent<ControlProps> = ({
  onClick,
  iconSize,
  sx = [],
  tabIndex = 0,
}) => {
  return (
    <ButtonBase
      aria-label="previous"
      onClick={onClick}
      sx={[controlStyles, ...toSxArray(sx)]}
      tabIndex={tabIndex}
    >
      <LeftArrow size={iconSize} rotate={0} />
    </ButtonBase>
  )
}

export const RightControl: FunctionComponent<ControlProps> = ({
  onClick,
  iconSize,
  sx = {},
  tabIndex = 0,
}) => {
  return (
    <ButtonBase
      aria-label="next"
      onClick={onClick}
      sx={[controlStyles, ...toSxArray(sx)]}
      tabIndex={tabIndex}
    >
      <LeftArrow size={iconSize} rotate={-180} />
    </ButtonBase>
  )
}
